import React from 'react'
import "../styles/Portfolio.css"
import laptopdbImage from "../assets/laptopdb.png"
import jobaidImage from "../assets/jobaid.png"
import loginImage from "../assets/login.png"
import pdfflowImage from "../assets/pdfflow.png"
import intrinsicImage from "../assets/Intrinsic.png"
import scormImage from "../assets/SCORM.png"

const card_data = [{
  id: 1,
  image: scormImage,
  title: "SCORM Virtual Tour",
  description: "An MVP of a virtual guided tour created using threejs with points of interest to examine, quizzes to test knowledge, and a final exam to test retention. The application is SCORM compliant, properly tracking progress and completion through an LMS.",
  link: {"Open": "https://brandongorman.me/ole/index.html"},
  technologies: ["SCORM API", "React", "Antd", "React Three Fiber", "Github Actions"]
},
{
  id: 2,
  image: intrinsicImage,
  title: "Intrinsic",
  description: "Intrinsic is a website that evaluates the intrinsic value of a stock based on various metrics, displaying an overall 'Quality' metric to the user. The website also allows users to search for stocks and view their financials.",
  link: {"Github": "https://github.com/bgorman87/Intrinsic", "Open": "https://intrinsic.brandongorman.me/"},
  technologies: ["Typescript", "React", "Express", "Docker", "Postgres", "GitHub", "Nginx", "Apache"]
},
{
  id: 3,
  image: laptopdbImage,
  title: "Laptop Database",
  description: "Laptop-DB is a web application for managing laptops and their associated parts. It allows users to search for laptop information down to the model/serial number and find associated parts.",
  link: {"Github": "https://github.com/bgorman87/laptop-db", "Open": "https://laptop-db.com/"},
  technologies: ["Django", "Bootstrap", "S3 Bucket", "GitHub", "CodePipeline", "cPanel", "sqlite3"]
},

{
  id: 4,
  image: pdfflowImage,
  title: "PDF Report Processor",
  description: "Multithreaded python tool that uses OCR to read PDF reports, rename them based on user defined templates and scanned content, sorts them into folders, and e-mails them to a contact list.",
  link: {"Github": "https://github.com/bgorman87/PDF-Flow", "Open": "https://pdfflow.godevservices.com/"},
  technologies: ["Python", "PySide6", "OCR", "MVVM Design", "GitHub", "sqlite3", "cl_Freeze", "AWS Services", "MySQL"]
},
{
  id: 5,
  image: jobaidImage,
  title: "Progressive Web Application",
  description: "Created a PWA to be used to contain job aid PDFs for various departments and applications. PWA was chosen to allow for desktop and mobile standard browsing, along with the stand alone application ability.",
  link: "",
  technologies: ["React", "S3 Bucket", "CloudFront", "Lambda@Edge", "Service Worker", "JWT", "IndexedDB"]
},
{
  id: 6,
  image: loginImage,
  title: "Login Page",
  description: "Used this project to practice creating React projects without create-react-app. Also learned about JSON Web Tokens and other authentication processes.",
  link: {"Github": "https://github.com/bgorman87/login-page"},
  technologies: ["React", "Django REST", "JWT", "Local Storage", "Webpack"]
}
]

const Cards = card_data.map(card => (
  <div key={card.id} className="card">
    <div className="image">
      <img src={card.image} alt={card.title} />
    </div>
    <div className="title-container">
      <div className="title">{card.title}</div>
      
      <div className="links">
        {card.link && Object.entries(card.link).map(([title, link]) => (
          <div key={title} className="link">
            <a href={link} className="link-button" target="_blank" rel="noreferrer">{title}</a>
          </div>
        ))}
      </div>
    </div>
    <hr/>
    <div className="description">{card.description}</div>
    
    <div className="technologies-title">Made With:</div>
    <div className="technologies">
      <div className='col'>
        {card.technologies.slice(0, Math.ceil(card.technologies.length / 2)).map(tech => (
          <p key={tech}>{tech}</p>
        ))}
      </div>
      <div className='col'>
        {card.technologies.slice(Math.ceil(card.technologies.length / 2)).map(tech => (
          <p key={tech}>{tech}</p>
        ))}
      </div>
    </div>
  </div>
));


const Portfolio = () => {
  return (
    <section id="portfolio" className='full-page portfolio'>
      <div className="card-container">
        {Cards}
      </div>
    </section>
  )
}

export default Portfolio
